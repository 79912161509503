<template>
  <div>
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
      <h4>Gestione IVA</h4>
      <div class="pretty-card">

        <div v-if="!isConfigurated">
          <div class="text-center m-5">
            <p class="text-center mb-4">
              Devi configurare la periodicità dell'IVA per usare questa pagina
            </p>
            <router-link :to="{ name: 'settings', params: { page: 'SettingsIVA'} }" tag="button" class="btn btn-fin">
              Configura
            </router-link>
          </div>
        </div>
        <div v-else>
          <h5 class="mb-3">{{previousPeriodTitle}}</h5>

          <div class="box primary-lighter">
              <div class="row">
                  <div class="col-4">
                      <b-form-group
                          id="input-group-iva-previous-credit-start"
                          :label="previousCreditStartLabel"
                          label-for="input-iva-previous-credit-start"
                          :class="{ 'invalid': !isValidPreviousCreditStart && showErrorPreviousCreditStart }"
                      >
                          <CurrencyInput v-model="previous.creditStart" @input="onInput" @blur="showErrorPreviousCreditStart = true" id="input-previous-credit-iva" :key="`previous_credit_iva_${true}`"/>
                          <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidPreviousCreditStart || !showErrorPreviousCreditStart }" >
                          <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </div>

                  <div class="col-4">
                      <b-form-group
                          id="input-group-credit-1"
                          label="IVA a credito"
                          label-for="input-credit-1"
                          class="disabled opaque"
                      >
                          <CurrencyInput v-model="previous.credit" id="input-credit-1" :key="`credit_1_${update}`" disabled/>
                      </b-form-group>
                  </div>

                  <div class="col-4">
                      <b-form-group
                          id="input-group-debt-1"
                          label="IVA a debito"
                          label-for="input-debt-1"
                          class="disabled opaque"
                      >
                          <CurrencyInput v-model="previous.debt" id="input-debt-1" :key="`debt_1_${update}`" disabled/>
                      </b-form-group>
                  </div>
              </div>
              <div class="row">
                  <div class="col-4">
                      <b-form-group
                          id="input-group-iva-previous-expected"
                          :label="previousExpectedPaymentLabel"
                          label-for="input-iva-previous-expected"
                          class="disabled opaque"
                      >
                          <CurrencyInput v-model="previous.expectedPayment" id="input-iva-previous-expected" :key="`iva_previous_expected_${update}`" disabled/>
                      </b-form-group>
                  </div>

                  <div class="col-4">
                      <b-form-group
                          id="input-group-previous-actual-payment"
                          :label="previousActualPaymentLabel"
                          label-for="input-previous-actual-payment"
                          :class="{ 'invalid': !isValidPreviousActualPayment && showErrorPreviousActualPayment }"
                      >
                          <CurrencyInput v-model="previous.actualPayment" @input="onInput" @blur="showErrorPreviousActualPayment = true" id="input-previous-actual-payment" :key="`iva_previous_actual_${true}`"/>
                          <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidPreviousActualPayment || !showErrorPreviousActualPayment }" >
                          <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </div>

                  <div class="col-4">
                      <b-form-group
                          id="input-group-credit-2"
                          :label="previousCreditEndLabel"
                          label-for="input-credit-2"
                          class="disabled opaque"
                      >
                          <CurrencyInput v-model="previous.creditEnd" id="input-credit-2" :key="`credit_2_${update}`" disabled/>
                      </b-form-group>
                  </div>
              </div>

              <div class="row mt-4">
                <div class="col-8">
                  <BankSelect ref="bankSelect" label="Banca" :banks="banks" :selectedItem="previous.bank" @update="updateBank" :key="`bank_select_${update}`"/>
                </div>
              </div>
          </div>

          <h5 class="mb-3 mt-4">{{currentPeriodTitle}}</h5>

          <div class="box primary-lighter">
            <div class="row">
              <div class="col-4">
                  <b-form-group
                      id="input-group-current-credit-start"
                      :label="currentCreditStartLabel"
                      label-for="input-current-credit-start"
                      :class="{ 'invalid': !isValidCurrentCreditStart && showErrorCurrentCreditStart }"
                  >
                      <CurrencyInput v-model="current.creditStart" @input="onInput" @blur="showErrorCurrentCreditStart = true" id="input-current-credit-start" :key="`current_credit_start_${true}`"/>
                      <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidCurrentCreditStart || !showErrorCurrentCreditStart }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                      </b-form-invalid-feedback>
                  </b-form-group>
              </div>

                <div class="col-4">
                    <b-form-group
                        id="input-group-current-credit"
                        label="IVA a credito"
                        label-for="input-current-credit"
                        class="disabled opaque"
                    >
                        <CurrencyInput v-model="current.credit" id="input-current-credit" :key="`current_credit_${update}`" disabled/>
                    </b-form-group>
                </div>

                <div class="col-4">
                    <b-form-group
                        id="input-current-debt"
                        label="IVA a debito"
                        label-for="input-current-debt"
                        class="disabled opaque"
                    >
                        <CurrencyInput v-model="current.debt" id="input-current-debt" :key="`current_debt_${update}`" disabled/>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b-form-group
                        id="input-group-payment"
                        :label="currentPaymentLabel"
                        label-for="input-payment"
                        class="disabled opaque"
                    >
                        <CurrencyInput v-model="current.payment" id="input-payment" :key="`iva_payment_${update}`" disabled/>
                    </b-form-group>
                </div>

                <div class="col-4">
                    <b-form-group
                        id="input-group-credit-4"
                        :label="currentCreditEndLabel"
                        label-for="input-credit-4"
                        class="disabled opaque"
                    >
                        <CurrencyInput v-model="current.creditEnd" id="input-credit-4" :key="`credit_4_${update}`" disabled/>
                    </b-form-group>
                </div>
            </div>

            <div class="row mt-4">
              <div class="col-8">
                <BankSelect ref="bankSelect2" label="Banca" :banks="banks" :selectedItem="current.bank" @update="updateBank2"/>
              </div>
            </div>
          </div>

          <IVAChart v-if="false" :series="series" :key="`iva_chart_${update}`" class="my-5"/>

          <div class="row mt-5">
            <div class="col-4 ml-auto">
              <custom-button
                :isLoading="loading"
                :class="{'disabled': !isValidForm}"
                label="Salva"
                class="w-100"
                @click.prevent.native="onSubmit"
              />
            </div>
          </div>
        </div>

        <ErrorCard v-if="errorMessage">
          <template #message >
            {{ errorMessage }}
          </template>
        </ErrorCard>
      </div>
    </div>

    <Snackbar :text="snackbarText" ref="snackbar" />
  </div>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { toFormattedDate, toStandardDate } from '@/helpers/dates.js';
// import { isFilled, isCurrency } from '@/helpers/formValidation.js';

import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import IVAChart from '@/views/components/Charts/IVAChart.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    BankSelect,
    BFormGroup,
    BFormInvalidFeedback,
    'custom-button': Button,
    CurrencyInput,
    ErrorCard,
    IVAChart,
    Loader,
    Snackbar,
  },
  data () {
    return {
      companyId: this.$oauth2.me.companies[0].id || null,

      errorMessage: '',
      snackbarText: '',
      loading: false,
      isLoadingPage: true,
      isConfigurated: false,
      update: 0,

      showErrorPreviousCreditStart: false,
      showErrorPreviousActualPayment: false,
      showErrorCurrentCreditStart: false,

      timer: null,

      banks: [],

      previous: {
        startDate: null,
        endDate: null,
        expiration: null,
        creditStart: null,
        credit: null,
        debt: null,
        expectedPayment: null,
        actualPayment: null,
        creditEnd: null,
        bank: null,
      },

      current: {
        startDate: null,
        endDate: null,
        expiration: null,
        creditStart: null,
        credit: null,
        debt: null,
        payment: null,
        creditEnd: null,
        bank: null,
      },
    };
  },
  computed: {
    isValidForm () {
      return this.isValidCurrentCreditStart && this.isValidPreviousCreditStart && this.isValidPreviousActualPayment;
    },
    currentPeriodTitle () {
      return `Periodo corrente ${toFormattedDate(this.current.startDate)} - ${toFormattedDate(this.current.endDate)}`;
    },
    currentCreditStartLabel () {
      return `Credito IVA al ${toFormattedDate(this.current.startDate)}`;
    },
    currentCreditEndLabel () {
      return `Credito IVA al ${toFormattedDate(this.current.endDate)}`;
    },
    currentPaymentLabel () {
      return `Versamento IVA del ${toFormattedDate(this.current.expiration)}`;
    },
    previousCreditStartLabel () {
      return `Credito IVA al ${toFormattedDate(this.previous.startDate)}`;
    },
    previousCreditEndLabel () {
      return `Credito IVA al ${toFormattedDate(this.previous.endDate)}`;
    },
    previousExpectedPaymentLabel () {
      return `Versamento IVA del ${toFormattedDate(this.previous.expiration)} previsto`;
    },
    previousActualPaymentLabel () {
      return `Versamento IVA del ${toFormattedDate(this.previous.expiration)} effettivo`;
    },
    previousPeriodTitle () {
      return `Periodo precedente ${toFormattedDate(this.previous.startDate)} - ${toFormattedDate(this.previous.endDate)}`;
    },
    isValidPreviousCreditStart () {
      return true;
      // return isFilled(this.previous.creditStart) && isCurrency(this.previous.creditStart);
    },
    isValidCurrentCreditStart () {
      return true;
      // return isFilled(this.current.creditStart) && isCurrency(this.current.creditStart);
    },
    isValidPreviousActualPayment () {
      return true;
      // return isFilled(this.previous.actualPayment) && isCurrency(this.previous.actualPayment);
    },
    series () {
      const series = [
        { period: 'Precedente', Inizio: this.previous.creditStart, Fine: this.previous.creditEnd },
        { period: 'Corrente', Inizio: this.current.creditStart, Fine: this.current.creditEnd },
      ];
      return series;
    },
  },
  methods: {
    getCompanyInfo () {
      this.errorMessage = '';
      this.$api.getCompany(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const company = res.data;
          this.banks = company.banks.filter(obj => !obj.isCash);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        });
    },
    getIVA () {
      this.errorMessage = '';
      this.$api.getIVA(this.companyId)
        .then((res) => {
          // console.log(res);
          if (res.data.iva !== null) {
            this.isConfigurated = true;
            this.updateData(res.data.iva, true);
          }
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    toFormattedDate (date) {
      return toFormattedDate(date);
    },
    toStandardDate (date) {
      return toStandardDate(date);
    },
    onInput () {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.recalculateIVA, 500);
    },
    onSubmit () {
      this.errorMessage = '';
      if (this.isValidForm) {
        this.saveData();
      } else {
        this.loading = true;
        if (!this.isValidPreviousCreditStart) {
          this.showErrorPreviousCreditStart = true;
          document.getElementById('input-group-iva-previous-credit-start').scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (!this.isValidPreviousActualPayment) {
          this.showErrorPreviousActualPayment = true;
          document.getElementById('input-group-previous-actual-payment').scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (!this.isValidCurrentCreditStart) {
          this.showErrorCurrentCreditStart = true;
          document.getElementById('input-group-current-credit-start').scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    saveData () {
      this.errorMessage = '';
      const previous = Object.assign({}, { creditStart: this.previous.creditStart, actualPayment: this.previous.actualPayment, bank: this.previous.bank });
      const current = Object.assign({}, { creditStart: this.current.creditStart, bank: this.current.bank });
      const form = Object.assign({}, { current: current, previous: previous });
      this.$log.debug(form);

      this.$api.updateIVA(this.companyId, form)
        .then((res) => {
          this.$log.debug(res);
          this.snackbarText = 'Informazioni aggiornate';
          this.$emit('openSnackbar', this.snackbarText);
          this.updateData(res.data.iva);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
      this.snackbarText = 'Informazioni aggiornate correttamente';
      this.$refs.snackbar.openSnackbar();
    },
    updateData (iva, partial = false) {
      if (!iva) return;

      this.previous.startDate = iva.previous?.start;
      this.previous.endDate = iva.previous?.end;
      this.previous.expiration = iva.previous?.expiration;
      this.previous.creditStart = iva.previous?.credito_iva_start;
      this.previous.credit = iva.previous?.iva_a_credito;
      this.previous.debt = iva.previous?.iva_a_debito;
      this.previous.expectedPayment = iva.previous?.versamento_iva_previsto;
      this.previous.actualPayment = iva.previous?.versamento_iva_effettivo;
      this.previous.creditEnd = iva.previous?.credito_iva_end;
      this.previous.bank = iva.previous?.bank;

      this.current.startDate = iva.current?.start;
      this.current.endDate = iva.current?.end;
      this.current.expiration = iva.current?.expiration;
      this.current.creditStart = iva.current?.credito_iva_start;
      this.current.credit = iva.current?.iva_a_credito;
      this.current.debt = iva.current?.iva_a_debito;
      this.current.payment = iva.current?.versamento_iva_previsto;
      this.current.creditEnd = iva.current?.credito_iva_end;
      this.current.bank = iva.current?.bank;

      this.update++;
    },
    updateBank (bank) {
      this.previous.bank = bank || null;
    },
    updateBank2 (bank) {
      this.current.bank = bank || null;
    },
    timeout () {
      setTimeout();
    },
    recalculateIVA () {
      this.errorMessage = '';
      const previous = { creditStart: this.previous.creditStart, actualPayment: this.previous.actualPayment };
      const current = { creditStart: this.current.creditStart };
      this.$api.recalculateIVA(this.companyId, { current, previous })
        .then((res) => {
          // console.log(res);
          this.updateData(res.data.iva, true);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        });
    },
  },
  mounted () {
    this.getCompanyInfo();
    this.getIVA();
  },
};
</script>

<style lang="scss">
</style>
